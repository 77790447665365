import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M44.4,3.1v45.4c0,1.7-1.4,3.1-3,3.1c-1.7,0-3.1-1.4-3.1-3.1v-10h-13c-1.6,0-2.8-1.2-2.8-2.8s1.2-2.9,2.8-2.9h13
          v-22L5.1,50.5c-0.5,0.6-1.4,1.1-2.2,1.1c-1.6,0-2.9-1.3-2.9-2.9c0-0.6,0.1-1.2,0.7-1.9L38.9,1.2C39.7,0.4,40.5,0,41.4,0
          C43.1,0,44.4,1.4,44.4,3.1z M68.5,0.6H57.2c-1.7,0-3,1.4-3,3v44.9c0,1.7,1.4,3.1,3,3.1c1.7,0,3.1-1.4,3.1-3.1V6.3h8
          c5.5,0,9,3.8,9,8.7c0,4.8-3.5,8.6-9,8.6h-1.9c-1.6,0-2.8,1.3-2.8,2.9s1.2,2.8,2.8,2.8h2.2c9.1,0,15.1-6.4,15.1-14.3
          C83.7,7,77.7,0.6,68.5,0.6z M93.7,0c-1.7,0-3,1.4-3,3.1v45.4c0,1.7,1.4,3.1,3,3.1c1.7,0,3.1-1.4,3.1-3.1V3.1C96.8,1.4,95.5,0,93.7,0
          z"
          fill="#64FFDA"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
