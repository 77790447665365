import React from 'react';

const IconMail = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Mail</title>
    <path
      d="M 41.945312 45.5 C 35.109375 45.5 -0.109375 17.117188 -0.109375 17.117188 L -0.109375 14 C -0.109375 10.136719 3.027344 7 6.898438 7 L 76.988281 7 C 80.863281 7 84 10.136719 84 14 L 83.945312 17.5 C 83.945312 17.5 49.109375 45.5 41.945312 45.5 Z M 41.945312 55.125 C 49.4375 55.125 83.945312 28 83.945312 28 L 84 70 C 84 73.863281 80.863281 77 76.988281 77 L 6.898438 77 C 3.03125 77 -0.109375 73.863281 -0.109375 70 L -0.0507812 28 C -0.0546875 28 35.109375 55.125 41.945312 55.125 Z M 41.945312 55.125 "
    />
  </svg>
);

export default IconMail;
